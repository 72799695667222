import React, { useEffect, useRef } from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from '../hooks/useImage';
import { ShapeType } from '../components/types.ts.tsx';


interface SvgShapeComponentProps {
    imageUrl: string;
    onSelect: () => void;
    isSelected: boolean;
    onChange: (newAttrs: Partial<ShapeType>) => void;
    onDuplicate: (newAttrs: Partial<ShapeType>) => void;
}

const SvgShapeComponent: React.FC<SvgShapeComponentProps> = ({
                                                                 imageUrl,
                                                                 onSelect,
                                                                 isSelected,
                                                                 onChange,
                                                                 onDuplicate,
                                                             }) => {
    const image = useImage(imageUrl);
    const imgRef = useRef<any>(null);
    const trRef = useRef<any>(null);

    useEffect(() => {
        if (isSelected && trRef.current && imgRef.current) {
            trRef.current.nodes([imgRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    const handleDragStart = (e: any) => {
        console.log("Drag started:", e);
        if (e.evt.altKey) {
            const node = imgRef.current;
            const newAttrs = {
                x: node.x(),
                y: node.y(),
                scaleX: node.scaleX(),
                scaleY: node.scaleY(),
                rotation: node.rotation(),
                svgUrl: imageUrl,
            };
            onDuplicate(newAttrs);
        }
    };

    return (
        <>
            {image && (
                <Image
                    ref={imgRef}
                    image={image} // This will only render if 'image' is not null
                    draggable
                    onClick={onSelect}
                    onDragStart={handleDragStart}
                    onDragEnd={(e) => onChange({ x: e.target.x(), y: e.target.y() })}
                    onTransformEnd={() => {
                        const node = imgRef.current;
                        onChange({
                            x: node.x(),
                            y: node.y(),
                            scaleX: node.scaleX(),
                            scaleY: node.scaleY(),
                            rotation: node.rotation(),
                        });
                    }}
                />
            )}
            {isSelected && <Transformer ref={trRef} />}
        </>
    );
};

export default SvgShapeComponent;
