import { useState, useEffect } from 'react';

const useImage = (url: string): HTMLImageElement | null => {
    const [image, setImage] = useState<HTMLImageElement | null>(null);

    useEffect(() => {
        const img = new Image();
        img.src = url;
        img.onload = () => setImage(img);
    }, [url]);

    return image;
};

export default useImage;
