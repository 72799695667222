import { useState, useRef, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ToolboxComponent from './components/ToolboxComponent';
import MainStageComponent from './components/MainStageComponent';

const App = () => {
    const [svgFiles, setSvgFiles] = useState<string[]>([]);
    const [shapes, setShapes] = useState<any[]>([]);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const stageRef = useRef<any>(null);

    useEffect(() => {
        const svgList = [
            '/svg/cloud.svg',
            '/svg/geolocation.svg',
            '/svg/location.svg',
            '/svg/night.svg',
            '/svg/ribbon.svg',
            '/svg/shield.svg',
        ];
        setSvgFiles(svgList);
    }, []);

    const handleAddShape = (svgUrl: string, position: { x: number, y: number }) => {
        const newShape = {
            id: Date.now(),
            svgUrl,
            x: position.x,
            y: position.y,
            scaleX: 1 / 3, // Start at 1/3 size
            scaleY: 1 / 3,
            rotation: 0,
        };
        setShapes([...shapes, newShape]);
    };

    const handleShapeChange = (id: number, newAttrs: any) => {
        const updatedShapes = shapes.map((shape) =>
            shape.id === id ? { ...shape, ...newAttrs } : shape
        );
        setShapes(updatedShapes);
    };

    const deleteShape = () => {
        if (selectedId) {
            const updatedShapes = shapes.filter((shape) => shape.id !== selectedId);
            setShapes(updatedShapes);
            setSelectedId(null);
        }
    };

    // Fonction pour exporter le stage en PNG
    const exportToPng = () => {
        const uri = stageRef.current.toDataURL(); // Convertir le stage en image base64
        const link = document.createElement('a');
        link.href = uri;
        link.download = 'canvas.png'; // Nom du fichier téléchargé
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <ToolboxComponent
                    svgFiles={svgFiles}
                    exportToPng={exportToPng}
                />
                <MainStageComponent
                    stageRef={stageRef}
                    shapes={shapes}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    handleShapeChange={handleShapeChange}
                    handleAddShape={handleAddShape}
                    deleteShape={deleteShape} // Passer la fonction deleteShape
                />
            </div>
        </div>
    );
};

export default App;
