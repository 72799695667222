import React from 'react';

interface ToolboxProps {
    svgFiles: string[];
    exportToPng: () => void;
}

const ToolboxComponent: React.FC<ToolboxProps> = ({ svgFiles, exportToPng }) => {
    const handleDragStart = (e: React.DragEvent<HTMLDivElement>, svgUrl: string) => {
        e.dataTransfer.setData('svgUrl', svgUrl); // Store SVG URL during drag
    };

    return (
        <div className="col-3 bg-light p-3">
            {svgFiles.map((svg, index) => (
                <div
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStart(e, svg)}
                    className="draggable-item mb-2"
                >
                    <img src={svg} alt={svg} width={50} height={50} />
                    Add {svg.split('/').pop()}
                </div>
            ))}
            <button className="btn btn-info w-100 mt-3" onClick={exportToPng}>
                Export to PNG
            </button>
        </div>
    );
};

export default ToolboxComponent;
