import React, { useEffect, RefObject } from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import SvgShapeComponent from './SvgShapeComponent';

// ShapeType interface to define the shape structure
interface ShapeType {
    id: number;
    svgUrl: string;
    x: number;
    y: number;
    scaleX: number;
    scaleY: number;
    rotation: number;
}

interface MainStageProps {
    stageRef: RefObject<any>;
    shapes: ShapeType[];
    selectedId: number | null;
    setSelectedId: (id: number | null) => void;
    handleShapeChange: (id: number, newAttrs: Partial<ShapeType>) => void;
    handleAddShape: (svgUrl: string, position: { x: number, y: number }) => void;
    deleteShape: () => void;
}

const MainStageComponent: React.FC<MainStageProps> = ({
                                                          stageRef,
                                                          shapes,
                                                          selectedId,
                                                          setSelectedId,
                                                          handleShapeChange,
                                                          handleAddShape,
                                                          deleteShape,
                                                      }) => {

    // Handle duplication of shapes
    const handleDuplicateShape = (newAttrs: Partial<ShapeType>) => {
        if (!newAttrs.x || !newAttrs.y) return; // Prevent errors by ensuring properties exist

        const newShape: ShapeType = {
            ...(newAttrs as ShapeType),
            id: Date.now(), // Assign a new ID for the duplicated shape
        };
        handleAddShape(newShape.svgUrl, { x: newShape.x, y: newShape.y });
    };

    // Delete the selected shape on 'Delete' key press
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Delete' || e.key === 'Backspace') {
                deleteShape();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [selectedId, deleteShape]);

    // Handle dropping a shape onto the stage
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        const stage = stageRef.current?.getStage(); // Ensure stageRef is not null
        if (!stage) return;

        const pointerPosition = stage.getPointerPosition(); // Get position where drop occurred
        if (!pointerPosition) return; // Prevent null errors

        const svgUrl = e.dataTransfer.getData('svgUrl');
        if (svgUrl) {
            const stagePosition = stage.position();
            const adjustedPosition = {
                x: pointerPosition.x - stagePosition.x,
                y: pointerPosition.y - stagePosition.y,
            };

            handleAddShape(svgUrl, adjustedPosition); // Add shape at the drop location
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

    const handleMouseDown = (e: any) => {
        if (stageRef.current?.getStage() && e.target === stageRef.current.getStage()) {
            setSelectedId(null); // Deselect all shapes if clicking on an empty area
        }
    };

    return (
        <div
            className="col-9"
            style={{ backgroundColor: '#d3d3d3' }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <Stage
                ref={stageRef}
                width={window.innerWidth * 0.75}
                height={window.innerHeight - 100}
                onMouseDown={handleMouseDown}
            >
                <Layer>
                    <Rect
                        width={window.innerWidth * 0.75}
                        height={window.innerHeight - 100}
                        fill="#d3d3d3"
                        listening={false} // Ensure this background doesn't capture events
                    />
                    {shapes.map((shape, i) => (
                        <SvgShapeComponent
                            key={i}
                            imageUrl={shape.svgUrl}
                            onSelect={() => setSelectedId(shape.id)}
                            isSelected={shape.id === selectedId}
                            onChange={(newAttrs) => handleShapeChange(shape.id, newAttrs)}
                            onDuplicate={handleDuplicateShape}
                        />
                    ))}
                </Layer>
            </Stage>
        </div>
    );
};

export default MainStageComponent;
